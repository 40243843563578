import { Injectable, EventEmitter, Output } from '@angular/core';
import { IResponse } from "../interfaces";
import { ApiService } from './api.service';
import { WalletHistoryTransModel, WalletHistoryTransFilterModel, WalletModel, BuyPostRequest, WithdrawBySectionsPostRequest, ProfitInfoModel, SellPostRequest, DividendsWithdrawModel, PromocodePurchaseModel, PaginationDataModel, CryptoUserFilterModel } from '../model';
import { WithdrawPostRequest } from '../model/request/withdrawPostRequest';
import { CurrencyType, ProfitType } from '../enums';
import { WithdrawCommission, currencyType } from '../constants';
import { FrozenTokensModel } from '../model/frozenTokens.model';
import { CreateEditPostRequest } from '../model/request/createEditPostRequest';
import { SwapFeeRequest } from '../model/request/swapFeeRequest';

const walletApiUrl = 'api/wallet/';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  @Output() public BalanceUpdateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() WithdrawEvent = new EventEmitter<boolean>();
  setWithdrawCompleted() {
    this.WithdrawEvent.emit();
  }

  constructor(private api: ApiService) {

  }
  private walletsBalances: any;
  private systemWallets: WalletModel[];
  private walletsData: WalletModel[] = [];
  private gasLimitsByOperation: Map<number, number>;

  //получение адреса системного кошелька для пополнения USDT
  public getInfo(): Promise<IResponse<string>> {
    return this.api.get<string>(walletApiUrl + "info").toPromise();
  }



  //инфа по всем балансам юзать рефреш
  // public getAllAsync(): Promise<IResponse<WalletModel[]>> {
  //   return this.api.get<WalletModel[]>(walletApiUrl + "list").toPromise();
  // }

  //инфа по транзам
  public getUserWalletHistoryTranz(filter: WalletHistoryTransFilterModel): Promise<IResponse<WalletHistoryTransModel[]>> {
    return this.api.post<WalletHistoryTransModel[]>(walletApiUrl + "getusertranz", filter).toPromise();
  }

  /** Инициализировать сервис */
  public initBalance(): Promise<WalletModel[]> {
    var promise: Promise<WalletModel[]>;
    if (this.getAllBalances() == undefined || this.getAllBalances() == null) {
      promise = this.refreshBalance();
    } else {
      promise = new Promise<WalletModel[]>((resolve, reject) => {
        resolve(this.getAllBalances())
      });
    }
    return promise;
  }

  // public clear(){
  //   this.walletsBalances = undefined;
  //   this.systemWallets= undefined;
  //   this.walletsData = undefined;
  // }

  // /**
  //  * Получение массива всех данных о балансах системный кошелёк вместе с внешним
  //  * @returns 
  //  */
  // public getWalletsData(){
  //   return this.walletsData;
  // }

  /**
   * просто получение мыссива кошельков где первый ключ это тип а второй ключ это название валюты
   * @returns
   */
  public getAllBalances(): WalletModel[] {
    return this.walletsBalances;
  }

  /**
   *
   * @param walletType тип кошелька
   * @param type название валюты (пока стринг)
   */
  public getBalance(walletType: number, type: string): number {
    if (!!this.walletsBalances && !!this.walletsBalances[walletType][type])
      return this.walletsBalances[walletType][type].balance;
    else return 0;
  }

  // /**
  //  *
  //  * @param type название валюты (пока стринг)
  //  */
  public getFullBalance(type: string): number {    
    var t = this;
    if (!!t.walletsBalances && !!t.walletsBalances[type]) { 
      return t.walletsBalances[type].balance;
    }
    else return 0;
  }

  // /**
  //  *
  //  * @param walletType
  //  */
  // public getWalletsByWalletType(walletType: number): WalletModel[] {
  //   if (walletType == 0) {
  //     return this.systemWallets;
  //   } else {
  //     return this.externalWallets;
  //   }
  // }

  // /**
  //  * обновление системного кошелька
  //  * @returns
  //  */
  public refreshBalance = (): Promise<WalletModel[]> => {    
    var t = this;
    return new Promise<WalletModel[]>((resolve, reject) => {
      this.api.get<WalletModel[]>(walletApiUrl + "list").toPromise()
        .then((resp) => {             
          let walletsBalances = [];
          resp.data.forEach(wal => {
            walletsBalances[wal.type] = wal;
          })      
          t.walletsBalances = walletsBalances; 
          t.BalanceUpdateEvent.emit();
          resolve(resp.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  }

  public getGasLimits(){
    return this.gasLimitsByOperation;
  }

  public async getOperationGasLimit( withdrawCommissionType: WithdrawCommission): Promise<number> {
    var t = this;
    if(!this.gasLimitsByOperation)
      await t.refreshGasLimits();
    return t.gasLimitsByOperation[withdrawCommissionType];
  }

  /** Обновление газ лимитов. */
  public refreshGasLimits = (): Promise<Map<number, number>> => {
    var t = this;
    return new Promise<Map<number, number>>((resolve, reject) => {
      t.api.get<Map<number, number>>(walletApiUrl + "operationGasLimits").toPromise()
      .then((resp) => {             
        t.gasLimitsByOperation = resp.data;
        resolve(resp.data);
      })
      .catch(er => {
        reject(er);
      });  
    });
  }

  public buy(buy: BuyPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'buy', buy).toPromise();
  }

  public requestWithdraw(withdraw: WithdrawPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'withdraw', withdraw).toPromise();
  }

  public sellToken(model: SellPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'sell', model).toPromise();
  }

  /**
   * получить балансы определенного юзера
   */
  public getBalanceById(userId: number) {
    var t = this;
    return t.api.get<WalletModel[]>(walletApiUrl + "getbalancebyid/" + userId).toPromise()
      // .then((resp) => {
      //   let walletsBalances: any[] = [];
      //   let systemWallets: any[] = [];
      //   walletsBalances[0] = [];
      //   walletsBalances[1] = [];
      //   resp.data.forEach(wal => {
      //     walletsBalances[wal.walletType][wal.type] = wal;
  //         systemWallets.push(wal);
      //   });
      //   t.walletsBalances = walletsBalances;
      //   t.systemWallets = systemWallets;
      //   t.walletsData = resp.data;
      //   return resp.data;
      // });
  }

  public getGrowthPercent(): Promise<IResponse<number>>{
    return this.api.get<number>(walletApiUrl + "getgrowthpercent").toPromise();
  }

  public createWithdrawAnalytics(filter: WithdrawBySectionsPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + "createWithdrawAnalytics", filter).toPromise();
  }

  public createWithdrawDividends(contractWithdraw: DividendsWithdrawModel): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'createWithdrawDividends', contractWithdraw).toPromise();
  }

  public unlockTokenById(tokenId: number): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'unlockTokenById/'+ tokenId, null).toPromise();
  }

  /** получение данных доступных к сожжению InvestProfitBalances*/
  public getAvailableProfits(currType: CurrencyType): Promise<IResponse<ProfitInfoModel[]>>{
    return this.api.get<ProfitInfoModel[]>(walletApiUrl + "getavailableprofits/" + currType).toPromise();
  }

  public getRegisteredPurchases(profitType: ProfitType, currencyType: CurrencyType): Promise<IResponse<number>> {
    return this.api.get<number>(walletApiUrl + `getRegisteredPurchases/${profitType}/${currencyType}`).toPromise();
  }

  public createReferralWithdraw(request: WithdrawPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'referralWithdrawRequest', request).toPromise();
  }
  
  public getPurchaseTransWithPromo(offset: number): Promise<IResponse<PromocodePurchaseModel[]>> {
    return this.api.get<PromocodePurchaseModel[]>(walletApiUrl + `getPurchaseTransWithPromo/${offset}`).toPromise();
  }

  public getLockedPurchases(filter: CryptoUserFilterModel): Promise<IResponse<PaginationDataModel<FrozenTokensModel[]>>> {
    return this.api.post<PaginationDataModel<FrozenTokensModel[]>>(walletApiUrl + 'getFrozenPurchasesByFilter', filter).toPromise();
  }

  public getProfitSumByType(type: ProfitType): Promise<IResponse<number>> {
    return this.api.get<number>(walletApiUrl + `getProfitSum/${type}`).toPromise();
  }

  public getFrozenPurchasesByTokenType(currencyType: CurrencyType): Promise<IResponse<FrozenTokensModel[]>> {
    return this.api.get<FrozenTokensModel[]>(walletApiUrl + 'getFrozenPurchasesByTokenType/' + currencyType).toPromise();
  }

  public updateLockedTokens(request: CreateEditPostRequest<FrozenTokensModel, number> ): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'updateLockedTokens', request).toPromise();
  }

  public getSwapFee(request: SwapFeeRequest ): Promise<IResponse<number>> {
    return this.api.post<number>(walletApiUrl + 'getSwapFee', request).toPromise();
  }

}
