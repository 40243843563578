import { TransactionType } from "../enums/transactionType";

export var transactionType = [
  { value: -200, label: "All" },
  { value: TransactionType.Receive, label: "Deposit" },
  { value: TransactionType.Send, label: "Withdraw" },
  { value: TransactionType.Buy, label: "Purchase" },
  { value: TransactionType.Sell, label: "Sell" },
  { value: TransactionType.WithdrawRipeIndexDividends, label: "Payouts withdrawal" },
  { value: TransactionType.WithDrawRef, label: "Referral withdraw" },
  { value: TransactionType.ClaimBonus, label: "Claim bonus" },
  { value: TransactionType.DividendsRequest, label: "Distribution of dividends" },
];