import { CurrencyType } from "../../enums";
import { ChainType } from "../../enums/chainType";

export class WithdrawPostRequest {
  ConfirmCode : string;
  AddressTo : string;
  Amount : number;
  CurrencyType : CurrencyType;
  CurrencyToWithdraw : CurrencyType;
  ActualRateId : number;
  GasPrice : number;
  GasLimit : number;
  useReducedGas : boolean;
  Chain : ChainType;
}