export enum TypeChart {

    AccumulatedAllUserProfit = 3,
    DefenseFund = 11,
    GasPrice = 31,

    //////
    Dividends = 4,

    TotalStreams = 1000,

    Indexes = 1001,

    TotalIncome = 1002,

    Streams = 1003,

    NotCumulativeStreams = 1004, 

    Presales = 1005,
}